#greeting{
    height: 100px;
    font-size: 100px;
    opacity: 1;
    animation: fade 1s ;
}

@keyframes fade {
    0%{opacity: 0;} 
    100% { opacity: 1;}
}

#mylink { 
    color: green;
}
#content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-left: 50px; */
    height: 100vh;
    width: 95vw;
    background-color: #0D1117;
    /* overflow-y:scroll; */
    color: whitesmoke;
    font-size: xx-large;
}

#message {
    padding: 20px;
    margin: 40px;
    font-family: sans-serif;
    font-size: 0.8em;
    color: rgb(190, 179, 179);
}

#ContactCard {
    position: absolute;
    margin-left: 77vw;
    margin-top: 82vh;
}