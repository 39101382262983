#SkillsContainer {
    display: grid;
    grid-template: 
        'chart tech'
        'lang lang';
    height: 100vh;
    --tech-skill-width: 44vw;
    --tech-skill-height: 55vh;
    --border-style: 5px solid #282C34;
}

#arrow {
    position: relative;
    font-size: 40px;
    font-weight: bolder;
    animation: point 0.5s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

@keyframes point {
 from {bottom: 0px;}
 to {bottom: 15px;}
}

#hint {
    justify-content: flex-start;
}

#ChartArea {
    grid-area: chart;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: var(--border-style);
    /* width: 600px; */
    /* height: 500px; */
    width: var(--tech-skill-width);
    height: var(--tech-skill-height);
    /* background-color: purple; */
    /* background-color: aqua; */
}


#TechDetail{
    grid-area: tech;
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin-left: 5px;
    align-items: center;
    justify-content: center;
    border: var(--border-style);
    width: var(--tech-skill-width);
    height: var(--tech-skill-height);
    /* background-color: brown; */
}

#LanguagesDetail {
    grid-area: lang;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 34vh;
    border: var(--border-style);
    margin-bottom: 25px;
    /* background-color: green; */
}