.ContactCard {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 4rem;
    /* background-color: blueviolet; */
}

/* * {
    border: 1px black solid;
} */

.imgicon {
    margin: 1rem;
    width: 3rem;
    height: 3rem;
    border-radius: 2rem;
}

.mailicon {
    width: 3rem;
    height: 3rem;
}
.weblinks {

}

.ContactBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.email {
    font-size: medium;
    color:  rgb(190, 179, 179);
}