.MsgDiv {
    display: flex;
    flex-direction: column;
    background-color: rgb(92, 233, 92);
    font-size: 1.2rem;
    width: 30vw;
    height: 30vh;
    border-radius: 1rem;
    color: black;
}

.MsgHeader {
    margin-left: 1rem;
}

.MsgACK {
    /* background-color: blue; */
    margin: 1rem;
}

.BtnOK {
    margin-left: auto;
    margin-right: 1rem;
    margin-bottom: 0.4rem;
    border-radius: 1rem;
    width: 8rem;
    background-color: #18A0FB;
    font-weight: bold;
}
