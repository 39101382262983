#SkillsDetail {
    /* background-color: purple; */
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto;
}

#SkillsetText {
    display: flex;
    /* color: gray; */
    text-decoration: underline;
    justify-content: center;
    align-items: center;
    height: 100px;
    grid-column-start: 1;
    grid-column-end: 5;
    font-size: xx-large;
    font-family: sans-serif;
}
