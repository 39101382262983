/* #LangDetail {
    display: grid;

    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
    background-color: blue;
    border: 2px blue solid;
    width: 500px;
    height: 200px;
} */

#LangDetail {
  /* background-color: yellow; */
  height: 33vh;
  width: 30vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "Title Title Title Title Title"
    "Description1  Js Python .  ."
    "Description2  Cpp Java . ."
    "Description3 Csharp Clang Bash Php";
  --card-width: 50px;
  --card-height: 50px;
}

#Title { 
    grid-area: Title;
    display: flex;
    /* background-color: blue; */
    justify-content: center;
    width: 89vw;
    text-decoration: underline;
    /* background-color: teal; */
 }
.Description1 { 
    grid-area: Description1; 
    /* background-color: darkblue; */
}

.Description2 { 
    grid-area: Description2; 
    /* background-color: darkblue; */
}

.Description3 { 
    grid-area: Description3; 
    width: 250px;
    /* background-color: darkblue; */
}

.Description1, .Description2, .Description3 {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
}

.js{
    grid-area: Js ;
}
.python{
    grid-area: Python;
}
.java{
    grid-area: Java ;
}
.cpp{
    grid-area: Cpp;
}
.clang{
    grid-area: Clang;
}
.csharp{
    grid-area: Csharp;
}
.php{
    grid-area: Php;
}
.bash{
    grid-area: Bash;
}

.js, .python, .java, .cpp, .clang, .csharp, .php, .bash  { 
    max-width: var(--card-width);
    max-height: var(--card-height);
    margin-right: 20px;
 }

