#Card {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100px;
    height: 100px;
    /* background-color: brown; */

}

#CardImg {
    max-width: 100px;
    max-height: 100px;

}

#CardText {
    /* background-color: green; */
    font-size: large;
    font-family: sans-serif;
    border: 2px solid white;
}