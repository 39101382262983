#contactform {
    display: grid;
    grid-gap: 0.2em;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "namelbl name name name"
    "emaillbl email email email"
    "purposelbl purpose purpose purpose"
    "msglbl message message message"
    ". . . sendbtn";
    text-align: end;
    width: 50vw;
    height:60vh;
    
}

#contactheader{
    display: flex;
    flex-direction: column;
    text-align: center;
    /* background-color: blue; */
    height: 2em;
    width: 20vw;
    padding-left: 3em;
    color:greenyellow;
}

#MessageACK {
    grid-area: msgbox;
    position: absolute;
    margin-left: 150vw;
    margin-top: 35vh;
    animation: slide 5s linear;
}

@keyframes slide{
    0% {
        margin-left: 150vw;
    }

    5%, 95% {
        margin-left: 60vw;
    }
    100% {
        margin-left: 150vw;
    }


}

#name {grid-area: name;}
#email {grid-area: email;}
#purpose {grid-area: purpose;}
#message { grid-area: message;}
#namelbl { grid-area: namelbl;}
#emaillbl { grid-area: emaillbl;}
#purposelbl { grid-area: purposelbl;}
#msglbl { grid-area: msglbl; }

#contactform > label {
    font-family: sans-serif;
    font-size: small;
    font-weight: bold;
    color: greenyellow;
    padding-top: 0.5rem;
    margin:0.2rem;
}

#contactform > input, textarea {
   background-color: #2C2C2C; 
   color: greenyellow;
   font-family: sans-serif;
}
#contactmsg {
    grid-area: message; 
    margin: 0px;
    width: 38.3vw;
    height: 30vh;
}

#contcard{
    position: absolute;
    margin-left: 77vw;
    margin-top: 82vh;
}

#sendbtn {
    grid-area: sendbtn;
    height: 2rem;
    width: 10rem;
    margin-top: 1rem;
    margin-left: 3.8rem;
    background-color: #18A0FB;
    font-weight: bolder;
    border-radius: 3rem;
    text-decoration: none;
}
