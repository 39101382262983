#nav-item {
    display: flex;
    align-items: center;
    padding-left: 0px;
    padding-bottom: 40px;
    list-style-type: none;
    transition: all 200ms ease;
    color: #C59A9B;
}

#image {
    width: 40px;
    height: 40px;
}
#nav-text {
    text-decoration: none;
    display: none;
    padding-left: 15px;
    font-family: 'sans-serif';
    font-size: x-large;
}

