:root {
  --navbar-size: 60px;
  overflow-x:hidden;
}

.App{
  height: 100vh;
}

#NavArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    width: var(--navbar-size);
    background-color: #282C34;
    transition: width 200ms ease;
    padding-left: 10px;
    position: fixed;
    filter: grayscale(100%);
}

#NavArea:hover{
    width: 150px;
    filter: grayscale(0%);
}

#NavArea:hover #nav-text{
  display: inline-block;
  color: #C59A9B;
}

#ContentArea {
   display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: var(--navbar-size);
    padding: 20px;
    height: 100vh;
    width: 95vw;
    background-color: #0D1117;
    overflow-y:scroll;
    color: whitesmoke;
    font-size: xx-large;;
}

::-webkit-scrollbar {
  width: 0.50rem;
}

::-webkit-scrollbar-track {
  background:#1e1e24;
}

::-webkit-scrollbar-thumb {
  background: #6649b8;
}

