#Projects {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Courier New', Courier, monospace;
    /* background-color: aqua; */
}

#github-logo{
    max-width: 50px;
    max-height: 50px;
    border-radius: 50px;
    margin-left: 5px;
}

#link {
    color: white;
    text-decoration: none;
}

#github-msg {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 90vw;
    height: 10vh;
    margin-top: 10vh;
    font-size: x-large;
    /* background-color: green; */
}